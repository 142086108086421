<template>
    <div class="">
        <Header />
        <input type='number' id='pinInput' ref="input">
        <button class="" 
            v-on:click="back()">
            Back
        </button>
        <button class="" 
            v-on:click="pinCheck()">
            Mentés
        </button>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";

export default {
    name: "TimeLinePin",
    components: {
        Header,
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
        }
    },
    mounted() {
        if (localStorage.getItem('user_pin')) {
             this.$router.push('/timelineedit');
        }
    },
    methods: {
        saveToTimeline() {
            const search_input = document.querySelector('#pinInput').value;
            this.axios.post(this.baseURL + `/wp-json/wp/v2/timelinebypin`, {entity_id: this.id, pin: search_input, language: 'en'}, {})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    alert(response_data.error);
                } else {
                    console.log(response);
                }
            }).catch( (error) => {
                console.log(error);
            });
        }, 
        pinCheck() {
            const search_input = document.querySelector('#pinInput').value;
            this.axios.get(this.baseURL + `/wp-json/wp/v2/pin_check`, {params: {'user_pin': search_input}})
            .then(response => {
                const response_data = response.data;
                if (response_data.error) {
                    alert(response_data.error);
                } else {
                    this.$router.push('/timelinetv?user_pin=' + search_input);
                }
            }).catch( (error) => {
                console.log(error);
            });
        },
        back() {
            console.log('Back');
        }
    },
};
</script>
